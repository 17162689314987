import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { Anchor } from '@sso/shared/core';

function Disclaimer() {
    const [MOUNT_NODE, setMountNode] = useState(document.getElementById('recaptcha-disclaimer'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (!document.body.contains(MOUNT_NODE)) {
            setMountNode(document.getElementById('recaptcha-disclaimer'));
        }
    });

    if (MOUNT_NODE) {
        return ReactDOM.createPortal(
            <>
                This site is protected by reCAPTCHA and the{' '}
                <Anchor href="https://policies.google.com/privacy" target="_blank">
                    Google Privacy Policy
                </Anchor>{' '}
                and{' '}
                <Anchor href="https://policies.google.com/terms" target="_blank">
                    Terms of Service
                </Anchor>{' '}
                apply.
            </>,
            MOUNT_NODE,
        );
    }

    return null;
}

export default Disclaimer;
