import styled from 'styled-components';
import { colors, media } from '@ovpn-ui/styles';

import { Box } from '@sso/shared/core';

const FixedFooter = styled(Box)`
    position: fixed;
    bottom: 24px;
    z-index: 2;

    justify-content: flex-start;

    width: 100%;
    margin-bottom: -24px;
    margin-left: -32px;
    padding: 24px 32px;

    background: rgba(255, 255, 255, 0.85);
    border-top: 1px solid ${colors.neutralBgHover};
    backdrop-filter: blur(2px);

    ${media.SM`
        padding: 24px;
        margin-left: -24px;
    `};

    ${media.XS`
        padding: 24px 16px;
        margin-left: -16px;
    `};
`;

export default FixedFooter;
