export type RouteKey =
    | keyof typeof navigation.support
    | keyof typeof navigation.cvpn
    | keyof typeof navigation.base
    | keyof typeof navigation.as;

export type Route =
    | typeof domainRoutes[keyof typeof domainRoutes]
    | typeof routes[keyof typeof routes];

export const params = {
    provider: '/:provider',
    product: '/:product',
    domain: '/:domain',
} as const;

export const paths = {
    notFound: '/not-found',
    productSelect: '/product-select',

    signin: '/signin',
    signup: '/signup',
    logout: '/logout',

    aws: '/aws',

    prevCreds: '/previous-credentials',
    oauth: '/oauth',
    username: '/username',

    profile: '/profile',

    emailVerification: '/email-verification',
    mfaVerification: '/mfa-verification',

    passwordVerification: '/password-verification',
    passwordCreation: '/password-creation',

    password: '/password',
    forgot: '/forgot',
    reset: '/reset',

    confirmation: '/confirmation',

    domainEnrollment: '/domain-enrollment',
    domainRecovery: '/domain-recovery',
    domainSelect: '/domain-select',

    userEmailVerification: '/user/email-verification',
    userActivation: '/user/activation',

    samlError: '/saml-error',

    totpQRCode: '/mfa/totp/qr-code',
    totpCode: '/mfa/totp/text-code',
    totpFinalize: '/mfa/totp/finalize',
} as const;

export const routes = {
    notFound: paths.notFound,
    logout: paths.logout,

    // signup
    signupProductSelect: `${paths.signup}${paths.productSelect}`,
    signupOauth: `${paths.signup}${paths.oauth}${params.provider}`,

    signup: `${paths.signup}${params.product}`, // all
    signupEmailVerification: `${paths.signup}${params.product}${paths.emailVerification}`, // all

    signupPasswordCreation: `${paths.signup}${params.product}${paths.passwordCreation}`, // as/cvpn
    signupProfile: `${paths.signup}${params.product}${paths.profile}`, // as/cvpn

    signupUserEmailVerification: `${paths.signup}${params.product}${paths.userEmailVerification}`, // cvpn/support

    signupDomainEnrollment: `${paths.signup}${params.product}${paths.domainEnrollment}`,
    signupAws: `${paths.signup}${params.product}${paths.aws}`,

    // signin
    signinProductSelect: `${paths.signin}${paths.productSelect}`,
    signinOauth: `${paths.signin}${paths.oauth}${params.provider}`,

    signin: `${paths.signin}${params.product}`, // as/support

    signinPasswordVerification: `${paths.signin}${params.product}${paths.passwordVerification}`, // as/cvpn
    signinPasswordCreation: `${paths.signin}${params.product}${paths.passwordCreation}`, // as/cvpn
    signinPasswordCreationConfirmation: `${paths.signin}${params.product}${paths.passwordCreation}${paths.confirmation}`, // as/cvpn

    signinMfaVerification: `${paths.signin}${params.product}${paths.mfaVerification}`, // all

    signinDomainSelect: `${paths.signin}${params.product}${paths.domainSelect}`,
    signinDomainRecovery: `${paths.signin}${params.product}${paths.domainRecovery}`,
    signinDomainRecoveryConfirmation: `${paths.signin}${params.product}${paths.domainRecovery}${paths.confirmation}`,

    signinSamlError: `${paths.signin}${params.product}${paths.samlError}`,
    signinPrevCreds: `${paths.signin}${params.product}${paths.prevCreds}`,

    // password recovery
    passwordForgot: `${paths.password}${params.product}${paths.forgot}`, // as/support
    passwordEmailVerification: `${paths.password}${params.product}${paths.emailVerification}`, // as/support
    passwordMfaVerification: `${paths.password}${params.product}${paths.mfaVerification}`, // as/support
    passwordReset: `${paths.password}${params.product}${paths.reset}`, // as/support
} as const;

export const domainRoutes = {
    // signin
    signin: `${paths.signin}${params.product}${params.domain}`,
    signinUsername: `${paths.signin}${params.product}${params.domain}${paths.username}`,
    signinPassword: `${paths.signin}${params.product}${params.domain}${paths.password}`,
    signinMfaVerification: `${paths.signin}${params.product}${params.domain}${paths.mfaVerification}`,

    signinDomainUserActivation: `${paths.signin}${params.product}${params.domain}${paths.userActivation}`,

    signinDomainTotpQRCode: `${paths.signin}${params.product}${params.domain}${paths.totpQRCode}`,
    signinDomainTotpCode: `${paths.signin}${params.product}${params.domain}${paths.totpCode}`,
    signinDomainTotpFinalize: `${paths.signin}${params.product}${params.domain}${paths.totpFinalize}`,

    // password recovery
    passwordForgot: `${paths.password}${params.product}${params.domain}${paths.forgot}`,
    passwordEmailVerification: `${paths.password}${params.product}${params.domain}${paths.emailVerification}`,
    passwordMfaVerification: `${paths.password}${params.product}${params.domain}${paths.mfaVerification}`,
    passwordReset: `${paths.password}${params.product}${params.domain}${paths.reset}`,
} as const;

export const navigation = {
    base: {
        signupProductSelect: routes.signupProductSelect,
        signupOauth: routes.signupOauth,

        signinProductSelect: routes.signinProductSelect,
        signinOauth: routes.signinOauth,

        notFound: routes.notFound,
        logout: routes.logout,
    },
    support: {
        // signup
        signup: routes.signup,
        signupEmailVerification: routes.signupEmailVerification,

        signupUserEmailVerification: routes.signupUserEmailVerification,

        // signin
        signin: routes.signin,
        signinMfaVerification: routes.signinMfaVerification,

        // password recovery
        passwordForgot: routes.passwordForgot,
        passwordEmailVerification: routes.passwordEmailVerification,
        passwordMfaVerification: routes.passwordMfaVerification,
        passwordReset: routes.passwordReset,
    },
    as: {
        // signup
        signup: routes.signup,
        signupEmailVerification: routes.signupEmailVerification,

        signupPasswordCreation: routes.signupPasswordCreation,
        signupProfile: routes.signupProfile,

        // signin
        signinPasswordVerification: routes.signinPasswordVerification,

        signinPasswordCreation: routes.signinPasswordCreation,
        signinPasswordCreationConfirmation: routes.signinPasswordCreationConfirmation,

        signin: routes.signin,
        signinMfaVerification: routes.signinMfaVerification,

        // password recovery
        passwordForgot: routes.passwordForgot,
        passwordEmailVerification: routes.passwordEmailVerification,
        passwordMfaVerification: routes.passwordMfaVerification,
        passwordReset: routes.passwordReset,
    },
    cvpn: {
        // signup
        signup: routes.signup,
        signupEmailVerification: routes.signupEmailVerification,

        signupPasswordCreation: routes.signupPasswordCreation,
        signupProfile: routes.signupProfile,

        signupDomainEnrollment: routes.signupDomainEnrollment,

        signupUserEmailVerification: routes.signupUserEmailVerification,

        signupAws: routes.signupAws,

        // signin
        signinPasswordVerification: routes.signinPasswordVerification,

        signinPasswordCreation: routes.signinPasswordCreation,
        signinPasswordCreationConfirmation: routes.signinPasswordCreationConfirmation,

        signinPrevCreds: routes.signinPrevCreds,

        signinDomainSelect: routes.signinDomainSelect,

        signinDomainRecovery: routes.signinDomainRecovery,
        signinDomainRecoveryConfirmation: routes.signinDomainRecoveryConfirmation,

        signinSamlError: routes.signinSamlError,

        signin: domainRoutes.signin,
        signinUsername: domainRoutes.signinUsername,
        signinPassword: domainRoutes.signinPassword,
        signinMfaVerification: domainRoutes.signinMfaVerification,

        signinDomainUserActivation: domainRoutes.signinDomainUserActivation,

        signinDomainTotpFinalize: domainRoutes.signinDomainTotpFinalize,
        signinDomainTotpQRCode: domainRoutes.signinDomainTotpQRCode,
        signinDomainTotpCode: domainRoutes.signinDomainTotpCode,

        // password recovery
        passwordForgot: domainRoutes.passwordForgot,
        passwordEmailVerification: domainRoutes.passwordEmailVerification,
        passwordMfaVerification: domainRoutes.passwordMfaVerification,
        passwordReset: domainRoutes.passwordReset,
    },
} as const;

export const fallback = {
    cvpn: [domainRoutes.signin, routes.signinDomainSelect, routes.signinProductSelect],
    support: [routes.signin, routes.signinProductSelect],
    as: [routes.signin, routes.signinProductSelect],
} as Record<string, Route[]>;
