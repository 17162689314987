export type UserAuthorityTypes = typeof UserAuthorityTypes[keyof typeof UserAuthorityTypes];
export type OwnerAuthType = typeof OwnerAuthType[keyof typeof OwnerAuthType];
export type AuthType = typeof AuthType[keyof typeof AuthType];
export type MfaType = typeof MfaType[keyof typeof MfaType];

export const CaptchaKind = {
    EGRC_INV: 'EGRC-INV',
    EGRC_CB: 'EGRC-CB',
    CFT_INV: 'TRNSTL',
    CFT_CB: 'TRNSTL-CB',
} as const;

export const MfaType = {
    Email: 'EMAIL',
    Totp: 'TOTP',
    None: 'NONE',
} as const;

export const OwnerAuthType = {
    Microsoft: 'MICROSOFT',
    Password: 'PASSWORD',
    Google: 'GOOGLE',
} as const;

export const AuthProviderName = {
    [OwnerAuthType.Microsoft]: 'Microsoft',
    [OwnerAuthType.Google]: 'Google',
} as Record<OwnerAuthType, string>;

export const AuthType = {
    Internal: 'INTERNAL',
    Ldap: 'LDAP',
    Saml: 'SAML',
} as const;

export const UserAuthorityTypes = {
    EnrolledOwner: 'ENROLLED_OWNER',
    Owner: 'OWNER',

    CloudAdmin: 'PRODUCT_ADMIN',
    Member: 'MEMBER',
} as const;
