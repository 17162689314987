import styled from 'styled-components';
import { media } from '@ovpn-ui/styles';

import logo from './background-logo.svg';
import blur from './background-blur.svg';

export const Container = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;

    width: 100%;
    min-height: 100vh;

    background-image: url(${logo}), url(${blur});
    background-repeat: no-repeat;

    background-position: bottom -96px right -112px, top -200px left -200px;
    background-size: 600px, 400px;

    ${media.lessThen(1024)`
        background-position: bottom -48px right -64px, top -100px left -100px;
        background-size: 400px, 200px;
    `}

    ${media.XS`
        background-image: none;
    `}
`;

export const Main = styled.main`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: center;

    padding: 32px 48px;

    ${media.XS`
        padding: 0;
    `}
`;
