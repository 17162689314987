import React, { useCallback, useMemo, useRef } from 'react';
import { COOKIES_ERROR, isOnline } from '@ovpn-ui/utils';

import { errorCodes } from '@sso/shared/constants';
import { toast } from '@sso/shared/utils';

import { Modes, captchaErrorMessage } from './Captcha.constants';
import CaptchaError from './Captcha.error';
import Disclaimer from './Disclaimer';
import Recaptcha from './Recaptcha';
import Turnstile from './Turnstile';
import {
    formatCaptchaResponse,
    captchaSentryEvent,
    loadCaptchaScript,
    executeCaptcha,
    getCaptchaMode,
} from './Captcha.utils';
import {
    RecaptchaExecutor,
    TurnstileExecutor,
    CaptchaFormValues,
    DefaultFormProps,
    WithCaptcha,
} from './Captcha.props';

function withCaptcha<
    FormValues extends CaptchaFormValues,
    UnprotectedFormProps extends DefaultFormProps<FormValues>,
>(
    UnprotectedForm: React.ComponentType<UnprotectedFormProps>,
    { action = 'generic', disclaimer = true } = {},
) {
    function CaptchaForm({ onFormSubmit, ...props }: UnprotectedFormProps) {
        const turnstileRef = useRef<TurnstileExecutor>();
        const recaptchaRef = useRef<RecaptchaExecutor>();

        const withCaptchaResolve: WithCaptcha<FormValues> = useCallback(
            (func, attempt = 1) =>
                payload => {
                    const mode = getCaptchaMode(attempt);

                    return loadCaptchaScript(mode)
                        .then(() =>
                            executeCaptcha(
                                mode,
                                action,
                                recaptchaRef.current,
                                turnstileRef.current,
                            ),
                        )
                        .catch(e => {
                            const error = new CaptchaError(e);
                            captchaSentryEvent(mode, error);
                            return Promise.reject(error);
                        })
                        .then(token => {
                            if (!token) {
                                const error = new CaptchaError('Captcha empty response');
                                captchaSentryEvent(mode, error);
                                return Promise.reject(error);
                            }

                            return token;
                        })
                        .then(token => formatCaptchaResponse(mode, token))
                        .then(token => func(token, payload as FormValues))
                        .catch(e => {
                            const isServerCaptchaError =
                                e?.errorCode === errorCodes.internal.CAPTCHA_VALIDATION_ERROR;
                            const isClientResolveError = e instanceof CaptchaError;
                            const isCookiesError = e?.message === COOKIES_ERROR;

                            if (isCookiesError) {
                                return Promise.reject(e);
                            }

                            if (
                                (isServerCaptchaError || isClientResolveError) &&
                                mode !== Modes.Predefined &&
                                mode !== Modes.Disabled &&
                                attempt < 3
                            ) {
                                return withCaptchaResolve(func, attempt + 1)(payload);
                            }

                            if (isClientResolveError) {
                                return isOnline().then(online => {
                                    const message = online
                                        ? captchaErrorMessage
                                        : 'No Internet connection';

                                    toast.error(message);
                                    return Promise.reject(e);
                                });
                            }

                            return Promise.reject(e);
                        });
                },
            [],
        );

        const handleFormSubmit = useMemo(
            () =>
                withCaptchaResolve((captchaToken, payload) =>
                    onFormSubmit({ ...payload, captchaToken } as FormValues),
                ),
            [onFormSubmit, withCaptchaResolve],
        );

        return (
            <>
                <UnprotectedForm
                    {...(props as UnprotectedFormProps)}
                    withCaptcha={withCaptchaResolve}
                    onFormSubmit={handleFormSubmit}
                />
                <Turnstile sitekey={window._sso_env_.TURNSTILE_INV} ref={turnstileRef} />
                <Recaptcha sitekey={window._sso_env_.CB_CAPTCHA} ref={recaptchaRef} />
                {disclaimer && <Disclaimer />}
            </>
        );
    }

    return CaptchaForm;
}

export default withCaptcha;
