export const captchaErrorMessage = 'reCAPTCHA not resolved';

export const Modes = {
    // Overwrites
    Predefined: 'predefined',
    Disabled: 'disabled',

    // Cloudflare Turnstile mode
    TurnstileInvisible: 'turnstile-invisible',
    TurnstileCheckbox: 'turnstile-checkbox',

    // Google Recaptcha modes
    RecaptchaInvisible: 'recaptcha-invisible',
    RecaptchaCheckbox: 'recaptcha-checkbox',
};
