import styled from 'styled-components';

import { Box } from '@sso/shared/core';

export const Container = styled(Box).attrs({ flex: true })`
    justify-content: center;

    & > :first-child {
        margin-top: 32px;
    }
`;
