export type ProductType = typeof ProductType[keyof typeof ProductType];

export const ProductType = {
    Support: 'SUPPORT',
    Cloud: 'CVPN',
    As: 'AS',
} as const;

export const ProductName = {
    [ProductType.Support]: 'OpenVPN Support Center',
    [ProductType.Cloud]: 'CloudConnexa™',
    [ProductType.As]: 'Access Server',
} as Record<ProductType, string>;

export const ProductRedirect = {
    [ProductType.Support]: `${window._sso_env_.SUPPORT_HOST}/hc/en-us/requests`,
    [ProductType.As]: window._sso_env_.AS_PORTAL_HOST,
    [ProductType.Cloud]: window._sso_env_.CLOUD_HOST,
} as Record<ProductType, string>;

export const SignupStepsCount = {
    [ProductType.Support]: 2,
    [ProductType.Cloud]: 4,
    [ProductType.As]: 3,
} as Record<ProductType, number>;
