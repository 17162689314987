import React from 'react';
import { isRejectedWithValue, TypedStartListening } from '@reduxjs/toolkit';
import { setAuthToken } from '@ovpn-ui-toolkit/mf-common';
import { isTrue } from '@ovpn-ui/utils';

import SupportToast from '@sso/shared/components/SupportToast';
import { ApiError } from '@sso/shared/types';
import {
    getDefaultErrorMessage,
    getMessageFromDetails,
    captureSentryEvent,
    getSSORedirectUrl,
    isErrorIgnored,
    toast,
} from '@sso/shared/utils';
import {
    AuthProviderName,
    errorMessages,
    errorCodes,
    OwnerAuthType,
    ProductType,
} from '@sso/shared/constants';

import { RootState } from './reducer';

export type AppStartListening = TypedStartListening<RootState>;

const errorListener = (startListening: AppStartListening) => {
    startListening({
        matcher: isRejectedWithValue,
        effect: (action, { getState, dispatch }) => {
            const error = action.payload as ApiError;

            let sentryMessage;
            let toastConfig;
            let message;

            if (error.errorCode) {
                if (isErrorIgnored(error)) {
                    captureSentryEvent(
                        `API Error [Silent]: ${error.errorCode}`,
                        errorMessages[error.errorCode] ||
                            `Unexpected error code received: ${error.errorCode}`,
                    );
                    return;
                }

                switch (error.errorCode) {
                    case errorCodes.internal.OAUTH_LOGIN_FAIL: {
                        const { product } = getState().product;

                        message = errorMessages[errorCodes.internal.OAUTH_LOGIN_FAIL];

                        if (product === ProductType.Cloud) {
                            [message] = message.split('.');
                        }

                        const provider = getMessageFromDetails(error, 'provider') as OwnerAuthType;

                        if (provider) {
                            message.replace('Oauth', AuthProviderName[provider]);
                        }

                        toastConfig = {
                            autoClose: 10000,
                        };

                        break;
                    }

                    case errorCodes.internal.WRONG_PASSWORD_FORCE_LOGOUT: {
                        if (window._env_) {
                            window.location.href = getSSORedirectUrl('logout');
                        } else {
                            dispatch(setAuthToken());
                        }

                        break;
                    }

                    case errorCodes.internal.VALIDATION_SERVICE_FAIL:
                    case errorCodes.internal.BLACKLISTED_EMAIL:
                    case errorCodes.internal.ACCOUNT_BANNED: {
                        sentryMessage = 'Cannot proceed. Please contact Support';
                        message = <SupportToast />;
                        break;
                    }

                    case errorCodes.internal.REQUEST_VALIDATION_ERROR: {
                        message =
                            action.type !== 'ACCOUNT/AUTH_METHODS/SET_ALL/FAILURE'
                                ? errorMessages[errorCodes.internal.REQUEST_VALIDATION_ERROR]
                                : getMessageFromDetails(error, 'loginModes');
                        break;
                    }

                    case errorCodes.internal.EMAIL_RESEND_NOT_ALLOWED_YET: {
                        message = getMessageFromDetails(error, 'resend');
                        break;
                    }

                    case errorCodes.internal.PROHIBITED_DOMAIN: {
                        const domain = getMessageFromDetails(error, 'domain');
                        message = domain
                            ? `Domain ${domain} cannot be used`
                            : errorMessages[errorCodes.internal.PROHIBITED_DOMAIN];
                        break;
                    }

                    case errorCodes.internal.ACCOUNT_EXISTS: {
                        const awsMPUser = isTrue(getMessageFromDetails(error, 'awsMPUser'));
                        message = awsMPUser
                            ? 'Active account with this email address already exists. Please input another email address'
                            : errorMessages[errorCodes.internal.ACCOUNT_EXISTS];
                        break;
                    }

                    case errorCodes.internal.EMAIL_VALIDATION_ERROR: {
                        const reason = getMessageFromDetails(error, 'reason');

                        if (reason === 'Email exists as owner') {
                            const email = getMessageFromDetails(error, 'email');

                            sentryMessage = `This email is already in use! Try different email or ask ${email} to delete existing account to proceed with the change`;
                            message = (
                                <>
                                    <b>This email is already in use!</b>
                                    <br />
                                    Try different email or ask <b>{email}</b> to delete existing
                                    account to proceed with the change.
                                </>
                            );
                        }

                        break;
                    }

                    default:
                        break;
                }

                if (!message) {
                    message = errorMessages[error.errorCode];
                }

                if (!message) {
                    sentryMessage = ['blue', 'green'].includes(window._sso_env_.CLUSTER_NAME)
                        ? 'Something went wrong. Please try a minute later or contact Support'
                        : `Unexpected error code received: ${error.errorCode}`;
                    message = getDefaultErrorMessage(error);
                }
            } else {
                if (error.message === 'Unauthorized') {
                    captureSentryEvent('API Error [Silent]', error.message);
                    return;
                }

                message = error.message;
            }

            if (message) {
                captureSentryEvent(
                    `API Error${error.errorCode ? `: ${error.errorCode}` : ''}`,
                    sentryMessage || (message as string),
                );
                toast.error(message, toastConfig);
            } else {
                captureSentryEvent('API Error [Unhandled]', error.toString());
                console.error('Unhandled request error:', error); // eslint-disable-line
            }
        },
    });
};

export default errorListener;
