/* eslint-disable no-console */

import { AnyRecord } from '@sso/shared/types';

export default function logDebugInfo(jwt?: string, decodedJwt?: AnyRecord | null) {
    if (['blue', 'green'].includes(window._sso_env_.CLUSTER_NAME)) {
        return;
    }

    if (jwt) {
        console.groupCollapsed('JWT Raw');
        console.log(jwt);
        console.groupEnd();
    }

    if (decodedJwt) {
        console.groupCollapsed('JWT Decoded');
        console.log(
            ...Object.entries(decodedJwt).map(([key, val], i) => {
                const logValue = Array.isArray(val) ? `[${val.join(', ')}]` : val;

                return `${i === 0 ? ' ' : ''}${key.padEnd(15)}: ${logValue}\n`;
            }),
        );
        console.groupEnd();
    }

    if (window._sso_env_) {
        console.groupCollapsed('Env vars');
        console.log(
            ...Object.entries(window._sso_env_).map(
                ([key, val], i) => `${i === 0 ? ' ' : ''}${key.padEnd(18)}: ${val}\n`,
            ),
        );
        console.groupEnd();
    }
}
