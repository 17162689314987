import React from 'react';

import { useModal } from '@sso/shared/hooks';

// don't render modals when not needed,
// helps with modal local state reset between usages
function ModalProvider({ children }: { children: React.ReactNode }) {
    const modal = useModal();

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return modal.type ? <>{children}</> : null;
}

export default ModalProvider;
