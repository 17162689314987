import React, { useImperativeHandle, useCallback, forwardRef, useEffect, useRef } from 'react';

import { ExecutorProps, ExecutorPromise } from './Captcha.props';
import { Container } from './Captcha.styles';
import CaptchaError from './Captcha.error';

const RECAPTCHA_IFRAME_SELECTOR = 'iframe[src^="https://www.google.com/recaptcha"][src*="bframe"]';

const Recaptcha = forwardRef(({ sitekey }: ExecutorProps, ref) => {
    const renderObserverRef = useRef<MutationObserver | null>();
    const renderObserverTimeoutRef = useRef<NodeJS.Timeout>();

    const executePromiseRef = useRef<ExecutorPromise | null>();

    const containerRef = useRef<HTMLDivElement>(null);
    const instanceIdRef = useRef<number>();

    const handleReset = useCallback(() => {
        try {
            window.grecaptcha?.enterprise?.reset?.(instanceIdRef.current);
        } catch {
            /* ignored */
        }

        executePromiseRef.current = null;

        while (containerRef.current?.firstChild) {
            containerRef.current.removeChild(containerRef.current.firstChild);
        }

        clearTimeout(renderObserverTimeoutRef.current);

        renderObserverRef.current?.disconnect();
        renderObserverRef.current = null;
    }, []);

    const handleSuccess = useCallback(
        response => {
            executePromiseRef.current?.resolve?.(response);
            handleReset();
        },
        [handleReset],
    );

    const handleFailure = useCallback(
        (error?) => {
            executePromiseRef.current?.reject?.(error);
            handleReset();
        },
        [handleReset],
    );

    const expectCaptchaUIRender = useCallback(() => {
        const recaptchaIframe = document.querySelector(RECAPTCHA_IFRAME_SELECTOR);

        if (!recaptchaIframe) {
            renderObserverTimeoutRef.current = setTimeout(expectCaptchaUIRender, 500);
        } else {
            clearTimeout(renderObserverTimeoutRef.current);

            const handleMutation: MutationCallback = mutationsList =>
                mutationsList.forEach(mutation => {
                    const wasVisible = mutation.oldValue?.includes('visibility: visible');
                    const isHidden = (mutation.target as HTMLElement).style.cssText.includes(
                        'visibility: hidden',
                    );

                    if (isHidden && wasVisible) {
                        const error = new CaptchaError('Recaptcha widget challenge unresolved');
                        handleFailure(error);
                    }
                });

            renderObserverRef.current = new MutationObserver(handleMutation);
            renderObserverRef.current?.observe(
                recaptchaIframe.parentElement?.parentElement as Node,
                {
                    attributeFilter: ['style'],
                    attributeOldValue: true,
                    attributes: true,
                },
            );
        }
    }, [handleFailure]);

    const execute = useCallback(
        (action = 'generic') =>
            new Promise((resolve, reject) => {
                try {
                    if (window.grecaptcha?.enterprise && sitekey) {
                        executePromiseRef.current = { resolve, reject };

                        const view = document.createElement('div');
                        containerRef.current?.appendChild(view);

                        const options = {
                            'expired-callback': handleFailure,
                            'error-callback': handleFailure,
                            callback: handleSuccess,
                            size: 'invisible' as const,
                            sitekey,
                            action,
                        };

                        expectCaptchaUIRender();
                        instanceIdRef.current = window.grecaptcha?.enterprise?.render(
                            view,
                            options,
                        );
                    } else {
                        const error = new CaptchaError('Recaptcha render script missing');
                        reject(error);
                    }
                } catch {
                    const error = new CaptchaError('Recaptcha execute error');
                    reject(error);
                }
            }),
        [expectCaptchaUIRender, handleFailure, handleSuccess, sitekey],
    );

    useImperativeHandle(ref, () => ({ execute }), [execute]);

    useEffect(() => handleReset, [handleReset]);

    return <Container ref={containerRef} />;
});

export default Recaptcha;
