import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export default function useFirstRendering(): boolean {
    const isFirstRendering = useRef(true);

    const { pathname } = useLocation();

    useEffect(() => {
        if (isFirstRendering.current) {
            isFirstRendering.current = false;
        }
    }, [pathname]);

    return isFirstRendering.current;
}
