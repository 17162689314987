import styled from 'styled-components';
import { styledIf } from '@ovpn-ui/styles';

import { Box } from '@sso/shared/core';

export const Caption = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 320px;
`;

export const Container = styled(Box)`
    display: flex;
    gap: 16px;

    word-break: break-word;

    ${styledIf('$column')`
        flex-direction: column;

        ${Caption} {
            max-width: none;
        }
    `}
`;

export const Content = styled(Box)`
    position: relative;

    display: flex;
    flex-direction: column;
`;
