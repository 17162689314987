import jwtDecode from 'jwt-decode';
import {
    configureStore as configureStoreOriginal,
    createListenerMiddleware,
    AnyAction,
} from '@reduxjs/toolkit';

import { createSentryMiddleware } from '@sso/shared/utils/sentry';

import reducer, { type RootState } from './reducer';
import errorListener from './errorListener';
import apiSlice from './apiSlice';

function getUserContext(state: RootState) {
    try {
        const jwt = jwtDecode(state.auth.token!);
        return {
            token: JSON.stringify(jwt, null, 2),
        };
    } catch (e) {
        return {};
    }
}

function breadcrumbDataFromAction(action: AnyAction) {
    return (
        action.payload && {
            payload: action.payload,
        }
    );
}

function filterBreadcrumbActions(action: AnyAction) {
    return !action.type.startsWith('api');
}

function configureStore(devToolsName = 'sso') {
    const listenerMiddleware = createListenerMiddleware<RootState>();
    const sentryMiddleware = createSentryMiddleware<RootState>({
        breadcrumbDataFromAction,
        filterBreadcrumbActions,
        getUserContext,
    });

    errorListener(listenerMiddleware.startListening);

    return configureStoreOriginal({
        reducer,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({ serializableCheck: false })
                .prepend(listenerMiddleware.middleware)
                .concat(apiSlice.middleware)
                .concat(sentryMiddleware),
        devTools: process.env.NODE_ENV === 'development' && {
            shouldHotReload: false,
            name: devToolsName,
        },
    });
}

export type AppStore = ReturnType<typeof configureStore>;

export default configureStore;
