import styled from 'styled-components';
import { media } from '@ovpn-ui/styles';

export const Container = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 8px;

    padding: 24px 112px;

    ${media.MD`
        padding: 24px 48px;
    `}

    ${media.XS`
        padding: 24px 16px;
    `}
`;
