import React from 'react';
import styled from 'styled-components';
import { media, colors } from '@ovpn-ui/styles';

import { shadows } from '@sso/shared/core';

export const Container = styled.div<{ className?: string; children: React.ReactNode }>`
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 592px;
    padding: 32px 64px;

    word-break: break-word;

    background-color: ${colors.neutralWhite};
    border: 1px solid ${colors.neutralBgHover};
    border-radius: 8px;
    box-shadow: ${shadows.sm};

    .ovpn-input-container {
        max-width: none;
    }

    ${media.lessThen(688)`
        padding: 32px;
    `}

    ${media.XS`
        padding: 24px 16px;

        border: none;
        box-shadow: none;
    `}
`;
