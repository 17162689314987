import { Hub, User, Event, makeMain, BrowserClient, Integrations, Severity } from '@sentry/react';
import { Action, AnyAction, Dispatch, Middleware } from 'redux';
import { ExtraErrorData } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/tracing';
import { Breadcrumb } from '@sentry/types';

const clientHub = new Hub();

export const Sentry = (callback: (hub: Hub) => void) => {
    const previousHub = makeMain(clientHub);
    clientHub.run(callback);
    makeMain(previousHub);
};

export const initSentry = () => {
    if (!process.env.SENTRY_RELEASE) return;

    Sentry((hub: Hub) => {
        hub.bindClient(
            new BrowserClient({
                environment: window._sso_env_.CLUSTER_NAME,
                release: process.env.SENTRY_RELEASE,
                dsn: window._sso_env_.SENTRY_DSN,
                autoSessionTracking: true,
                debug: false,
                integrations: [
                    new Integrations.Breadcrumbs(),
                    new Integrations.UserAgent(),
                    new ExtraErrorData(),
                    new BrowserTracing(),
                ],
                tracesSampleRate: 1.0,
                maxValueLength: 1000,
                normalizeDepth: 10,
            }),
        );
    });
};

export const captureSentryEvent = (type: string, value?: string) => {
    if (!process.env.SENTRY_RELEASE) return;

    Sentry((hub: Hub) => {
        hub.captureEvent({
            exception: {
                values: [
                    {
                        value,
                        type,
                    },
                ],
            },
        });
    });
};

export const addSentryBreadcrumb = (breadcrumb: Breadcrumb) => {
    if (!process.env.SENTRY_RELEASE) return;

    Sentry((hub: Hub) => {
        hub.addBreadcrumb({
            level: 'info' as Severity,
            ...breadcrumb,
        });
    });
};

const getType = (action: AnyAction) => action.type;
const identity = (x: any) => x;
const getUndefined = () => {};
const filter = () => true;

type Options<S> = {
    breadcrumbMessageFromAction?: ((action: Action) => any) | undefined;
    filterBreadcrumbActions?: ((action: Action) => boolean) | undefined;
    breadcrumbDataFromAction?: ((action: Action) => any) | undefined;
    actionTransformer?: ((action: Action) => any) | undefined;
    getTags?: ((state: S) => Event['tags']) | undefined;
    stateTransformer?: ((state: S) => any) | undefined;
    getUserContext?: ((state: S) => User) | undefined;
    breadcrumbCategory?: string | undefined;
};

export const createSentryMiddleware = <S>(options: Options<S> = {}): Middleware<unknown, S> => {
    const {
        breadcrumbDataFromAction = getUndefined,
        breadcrumbMessageFromAction = getType,
        breadcrumbCategory = 'redux-action',
        filterBreadcrumbActions = filter,
        actionTransformer = identity,
        stateTransformer = identity,
        getUserContext,
        getTags,
    } = options;

    initSentry();

    return store => {
        // assigning null is a workaround since sentry api normalizes the store data
        // and converts undefined to '[undefined]'
        let lastAction: AnyAction | null = null;

        Sentry((hub: Hub) => {
            hub.configureScope(scope => {
                scope.addEventProcessor(_event => {
                    const state = store.getState();
                    const event = _event;

                    event.extra = {
                        ...event.extra,
                        lastAction: actionTransformer(lastAction as AnyAction),
                        state: stateTransformer(state),
                    };

                    if (getUserContext) {
                        event.user = {
                            ...event.user,
                            ...getUserContext(state),
                        };
                    }

                    if (getTags) {
                        Object.entries(getTags(state) || {}).forEach(([key, value]) => {
                            event.tags = {
                                ...event.tags,
                                [key]: value,
                            };
                        });
                    }

                    return event;
                });
            });
        });

        return (next: Dispatch) => (action: AnyAction) => {
            if (filterBreadcrumbActions(action)) {
                addSentryBreadcrumb({
                    message: breadcrumbMessageFromAction(action),
                    data: breadcrumbDataFromAction(action),
                    category: breadcrumbCategory,
                });
            }

            lastAction = action;
            return next(action);
        };
    };
};
