import React, { Suspense, useEffect } from 'react';
import { Switch, Redirect, useLocation, Route } from 'react-router-dom';

import ErrorBoundary from '@sso/shared/components/ErrorBoundary';
import { Spinner } from '@sso/shared/core';

import { GuardedRouterProps } from './GuardedRouter.props';
import GuardedComponent from './GuardedComponent';

function GuardedRouter({
    fallback = <Spinner delayed absolute />,
    topLevel = false,
    redirect,
    children,
    routes,
}: GuardedRouterProps) {
    const { pathname } = useLocation();

    useEffect(() => {
        if (topLevel) window.scrollTo(0, 0);
    }, [topLevel, pathname]);

    return (
        <Suspense fallback={fallback}>
            <Switch>
                {routes.map(({ component: Component, ...route }) => (
                    <Route
                        render={props =>
                            route.guard || route.path.includes('/:') ? (
                                <GuardedComponent
                                    component={Component}
                                    guard={route.guard}
                                    {...props}
                                />
                            ) : (
                                <ErrorBoundary>
                                    <Component {...props} />
                                </ErrorBoundary>
                            )
                        }
                        exact={route.exact ?? true}
                        path={route.path}
                        key={route.path}
                    />
                ))}
                {children}
                {redirect && <Redirect to={redirect} />}
            </Switch>
        </Suspense>
    );
}

export default GuardedRouter;
